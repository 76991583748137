import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95')
];

export const server_loads = [3,2,8,10,11,13,7];

export const dictionary = {
		"/(app)": [30,[3],[4]],
		"/(admin)/admin": [16,[2]],
		"/(admin)/admin/auctioneers": [~17,[2]],
		"/(admin)/admin/cyberclerk": [~18,[2]],
		"/(admin)/admin/discounts": [~19,[2]],
		"/(admin)/admin/news": [~20,[2]],
		"/(admin)/admin/passports": [~21,[2]],
		"/(admin)/admin/places": [~22,[2]],
		"/(admin)/admin/qdrant": [23,[2]],
		"/(admin)/admin/reports": [~24,[2]],
		"/(admin)/admin/sales": [~25,[2]],
		"/(admin)/admin/sales/pending-publication": [26,[2]],
		"/(admin)/admin/transporters": [~27,[2]],
		"/(admin)/admin/unpaid": [28,[2]],
		"/(admin)/admin/users": [~29,[2]],
		"/(app)/auctioneer/[id]": [~41,[3],[4]],
		"/(app)/bids/[saleId]/autobidsPrint": [~42,[3],[4]],
		"/(collectivity)/collectivity": [67,[8],[9]],
		"/(collectivity)/collectivity/track/[saleId]": [~79,[8],[9]],
		"/(collectivity)/collectivity/[cpId]/import": [~68,[8],[9]],
		"/(collectivity)/collectivity/[cpId]/lots": [69,[8,10],[9]],
		"/(collectivity)/collectivity/[cpId]/lots/online": [~70,[8,10],[9]],
		"/(collectivity)/collectivity/[cpId]/lots/preparation": [~71,[8,10],[9]],
		"/(collectivity)/collectivity/[cpId]/photo/[saleId]": [72,[8],[9]],
		"/(collectivity)/collectivity/[cpId]/questions": [~73,[8,10],[9]],
		"/(collectivity)/collectivity/[cpId]/registrations": [~74,[8,10],[9]],
		"/(collectivity)/collectivity/[cpId]/results": [~75,[8,10],[9]],
		"/(collectivity)/collectivity/[cpId]/sale": [~76,[8],[9]],
		"/(collectivity)/collectivity/[cpId]/slips": [~77,[8,10],[9]],
		"/(collectivity)/collectivity/[cpId]/stats": [~78,[8,10],[9]],
		"/(app)/document": [43,[3],[4]],
		"/(live)/live": [80,[11],[12]],
		"/(live)/live/[cpId]": [~81,[11],[12]],
		"/(live)/live/[saleId]/commissaire": [82,[11],[12]],
		"/(live)/live/[saleId]/cyberclerc": [83,[11],[12]],
		"/(live)/live/[saleId]/encoder": [~84,[11],[12]],
		"/(live)/live/[saleId]/encoder/tiers": [~85,[11],[12]],
		"/(live)/live/[saleId]/spectateur": [86,[11],[12]],
		"/(app)/login": [44,[3,5],[4]],
		"/(app)/login/forgot-password": [45,[3,5],[4]],
		"/(app)/lot-history": [46,[3],[4]],
		"/(mobile)/mobile": [87,[13]],
		"/(mobile)/mobile/login": [92,[13,14]],
		"/(mobile)/mobile/login/forgot-password": [93,[13,14]],
		"/(mobile)/mobile/theme": [94,[13]],
		"/(mobile)/mobile/[cpId]/sale": [~88,[13]],
		"/(mobile)/mobile/[cpId]/sale/[saleId]/lots/live": [~89,[13]],
		"/(mobile)/mobile/[cpId]/sale/[saleId]/lots/online": [~90,[13]],
		"/(mobile)/mobile/[cpId]/sale/[saleId]/registrations": [~91,[13]],
		"/(payment)/payment/pay/[transferId]": [~95,[15]],
		"/(app)/payment/[id]/unpaid": [47,[3,6],[4]],
		"/(app)/payment/[id]/wu": [~48,[3,6],[4]],
		"/(app)/photo/[saleId]": [49,[3],[4]],
		"/(app)/sale/edit/[id]": [~61,[3],[4]],
		"/(app)/sale/import/[saleId]": [~62,[3],[4]],
		"/(app)/sale/track/[saleId]": [~63,[3],[4]],
		"/(app)/sale/[saleId]/absenteeBids": [~50,[3,7],[4]],
		"/(app)/sale/[saleId]/adjudications": [~51,[3,7],[4]],
		"/(app)/sale/[saleId]/autobids": [~52,[3,7],[4]],
		"/(app)/sale/[saleId]/bids": [~53,[3,7],[4]],
		"/(app)/sale/[saleId]/lots": [~54,[3,7],[4]],
		"/(app)/sale/[saleId]/minutes": [~55,[3,7],[4]],
		"/(app)/sale/[saleId]/questions": [~56,[3,7],[4]],
		"/(app)/sale/[saleId]/registrations": [~57,[3,7],[4]],
		"/(app)/sale/[saleId]/slips": [~58,[3,7],[4]],
		"/(app)/sale/[saleId]/sold": [59,[3,7],[4]],
		"/(app)/sale/[saleId]/stats": [60,[3,7],[4]],
		"/(app)/signup": [64,[3],[4]],
		"/(app)/theme": [65,[3],[4]],
		"/(app)/user": [66,[3],[4]],
		"/(app)/[id]/bidders": [~31,[3],[4]],
		"/(app)/[id]/bills": [~32,[3],[4]],
		"/(app)/[id]/home": [~33,[3],[4]],
		"/(app)/[id]/places": [~34,[3],[4]],
		"/(app)/[id]/sales": [~36,[3],[4]],
		"/(app)/[id]/sale/create": [~35,[3],[4]],
		"/(app)/[id]/steps": [~37,[3],[4]],
		"/(app)/[id]/transporters": [~38,[3],[4]],
		"/(app)/[id]/unpaid": [~39,[3],[4]],
		"/(app)/[id]/users": [~40,[3],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';